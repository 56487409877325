import './HomePage.css'
import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Chord from './Chord'
import Navbar from './Navbar'
import { TOP_VOLUME_MATRIX_QUERY } from './queries'
import EpochSlider, { initialProps } from './EpochSlider'

export default function HomePage ({ fromDate, setFromDate }) {
  const date = fromDate || new Date()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  function onGroupClick (group) {
    const url = `/miner/${group}`
    const search = searchParams.toString()
    const to = search ? `${url}?${search}` : url
    navigate(to)
  }
  return (
    <div className='avenir bg-white'>
      <Navbar />
      <Content onGroupClick={onGroupClick} date={date} setFromDate={setFromDate} />
    </div>
  )
}

function Content ({ date, setFromDate, onGroupClick }) {
  const sliderProps = initialProps(date)
  const [range, setRange] = useState(sliderProps.range)
  return (
    <>
      <div className='mw8 center'>
        <EpochSlider range={range} date={date} onDateChange={setFromDate} onRangeChange={setRange} />
      </div>
      <ChordContainer range={range} onLabelClick={onGroupClick} />
    </>
  )
}

function ChordContainer ({ range, onLabelClick }) {
  const queryVars = { fromHeight: range[0], toHeight: range[1] }
  const { loading, error, data } = useQuery(TOP_VOLUME_MATRIX_QUERY, { variables: queryVars })
  if (error) {
    console.error(error)
    return null
  }
  let matrix = []
  let names = []
  let totalFil = 0
  if (!loading) {
    matrix = data.topVolumeMatrix.data
    names = data.topVolumeMatrix.names
    totalFil = data.topVolumeMatrix.totalFil
  }
  return (
    <Chord
      loading={loading}
      data={matrix}
      names={names}
      totalFil={totalFil}
      width={window.innerWidth}
      height={Math.max(window.innerHeight - 375, 640)}
      onLabelClick={onLabelClick}
    />
  )
}
