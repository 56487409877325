import React from 'react'
import { BrowserRouter, Routes, useSearchParams } from 'react-router-dom'
import Auth from './Auth'
import FlowPage from './FlowPage'
import MinerPage from './MinerPage'
import PopulationPage from './PopulationPage'
import GroupsPage from './GroupsPage'

export default function App () {
  return (
    <React.StrictMode>
      <Auth>
        <BrowserRouter>
          <FromDate />
        </BrowserRouter>
      </Auth>
    </React.StrictMode>
  )
}

function FromDate () {
  const [searchParams, setSearchParams] = useSearchParams()
  const fromDate = getValidDate(searchParams.get('from'))
  const setFromDate = (date) => setSearchParams({ from: date.toISOString().split('T')[0] })
  return (
    <Routes>
      <PopulationPage path='/' fromDate={fromDate} setFromDate={setFromDate} />
      <FlowPage path='flow' fromDate={fromDate} setFromDate={setFromDate} />
      <MinerPage path='miner/:group' fromDate={fromDate} setFromDate={setFromDate} />
      <MinerPage path='miner/:group/:address' fromDate={fromDate} setFromDate={setFromDate} />
      <GroupsPage path='groups' />
    </Routes>
  )
}

function getValidDate (dateStr) {
  if (!dateStr) return null
  if (isNaN(Date.parse(dateStr))) {
    console.log(`${dateStr} is not a valid date`)
    return null
  }
  return new Date(dateStr)
}
