import React from 'react'
import { DateRangePicker } from 'rsuite'
import { genesis, startOfWeek, endOfWeek } from './utils'

export default function WeekPicker ({
  date,
  minDate = startOfWeek(genesis),
  maxDate = endOfWeek(new Date()),
  onChange
}) {
  const weekStart = startOfWeek(date)
  const weekEnd = endOfWeek(date)
  return (
    <DateRangePicker
      oneTap
      hoverRange='week'
      cleanable={false}
      value={[weekStart, weekEnd]}
      disabledDate={d => d.getTime() < minDate.getTime() || d.getTime() >= maxDate.getTime()}
      onChange={([d]) => onChange(startOfWeek(d))}
      ranges={[]}
    />
  )
}
