import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Loader } from 'rsuite'
import { LIST_GROUPS_QUERY } from './queries'
import Navbar from './Navbar'
import AddrLink from './AddrLink'
import Link from './Link'

// Crude regex to detect a filecoin address
const addrRegex = /^f[0-9]/

export function GroupsPage ({ loading, error, data = [] }) {
  return (
    <div className='App avenir tc bg-white'>
      <Navbar />
      <div className='mw8 center'>
        <h1 className='f3 tl'>Groups</h1>
        {loading ? <Loader center backdrop /> : null}
        {!loading && !error
          ? (
            <ul className='tl list pl0 ml0 center ba b--light-silver br2 f6' style={{ overflowWrap: 'normal' }}>
              {data.map((item, index) => (
                <li className='ph3 pv2 bb b--light-silver db lh-copy' key={item.name}>
                  <Link className='fw7 mr2' to={`/miner/${encodeURIComponent(item.name)}`}>{item.name}</Link>
                  <span className='br3 ph1 f7 bg-blue white mr2 '>{item.addresses.length}</span>
                  <AddressList groupName={item.name} addresses={item.addresses} />
                </li>
              ))}
            </ul>
            )
          : null}
      </div>
    </div>
  )
}

function AddressList ({ groupName, addresses }) {
  const [open, setOpen] = useState(false)
  let btn
  if (!open && addresses.length > 5) {
    const hiddenCount = addresses.length - 5
    addresses = addresses.slice(0, 5)
    const btnClass = 'link ph2 pv1 br1 mr1 mt1 dib fw5 f7 bg-transparent blue'
    btn = <button type='button' onClick={() => setOpen(true)} className={btnClass}>Show {hiddenCount} more...</button>
  }
  return addresses.map(a => <AddrLink key={a} group={groupName} address={a} />).concat(btn)
}

export default function GroupsPageContainer () {
  const { loading, error, data } = useQuery(LIST_GROUPS_QUERY)
  // Filter out the groups that are named after a filecoin address
  const groupsData = (data?.listGroups ?? [])
    .filter(({ name }) => !addrRegex.test(name))
    .sort((a, b) => a.name.localeCompare(b.name))
  return <GroupsPage loading={loading} error={error} data={groupsData} />
}
