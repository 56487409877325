import React from 'react'
import { useMatch } from 'react-router-dom'
import { logout } from './Auth'
import Link from './Link'
import logo from './fil-flow-logo.svg'
import AddressForm from './AddressForm'

// rsuite css adds underline to a:hover and a:focus...
const style = { textDecoration: 'none' }

export default function Navbar ({ currentAddress, children }) {
  return (
    <header className='w-100 pv3 relative'>
      <div className='mw8 center'>
        <nav className='flex'>
          <Link className='no-underline flex-none v-mid pl2 pr3 link black hover-blue nowrap' style={style} to='/' title='Overview'>
            <img src={logo} className='dib w2 v-mid' alt='logo' />
            <span className='ml2 fw7 dn di-ns f6 fw6 ttu tracked v-mid'>Flow</span>
          </Link>
          {children}
          <div className='flex-auto tl tr-ns'>
            <NavLink to='/'>Overview</NavLink>
            <NavLink to='/groups'>Groups</NavLink>
            <AddressForm currentAddress={currentAddress} className='dib ml3-ns v-mid' />
          </div>
        </nav>
      </div>
      <a className='link no-underline absolute top-1 right-1 link silver hover-blue pv2 pr1' style={style} href='/' onClick={logout} title='Log out'>
        <i className='gg-log-in' />
      </a>
    </header>
  )
}

function NavLink ({ to, ...props }) {
  const match = useMatch(to + '/*')
  const linkProps = (isCurrent) => ({
    className: `dn link no-underline glow hover-blue ml3 ml3-m ml4-ns fw7 f7 fw6 ttu tracked v-mid ${isCurrent ? 'bb b--blue blue' : 'black'}`,
    style
  })
  return <Link to={to} {...linkProps(match)} {...props} />
}
