import React, { useState } from 'react'
import * as d3 from 'd3'
import { useQuery } from '@apollo/client'
import Measure from 'react-measure'
import Chord from './Chord'
import './HomePage.css'
import Navbar from './Navbar'
import { POPULATION_FLOW_MATRIX_QUERY } from './queries'
import WeekPicker from './WeekPicker'
import { convertDateToEpoch, startOfWeek, endOfDayUTC, startOfDayUTC, addDays, stringToColour, dateFormat, dateRangeFormat } from './utils'
import NoData from './NoData'

const formatNumber = d3.format(',')

export default function PopulationPage ({ fromDate, setFromDate }) {
  const date = fromDate || startOfWeek(new Date())
  return (
    <div className='avenir bg-white'>
      <Navbar>
        <WeekPicker date={date} onChange={setFromDate} />
      </Navbar>
      <Content date={date} />
    </div>
  )
}

function Content ({ date }) {
  const dayRanges = Array.from(Array(7), (_, i) => {
    const d = addDays(date, i)
    return [startOfDayUTC(d), endOfDayUTC(d)]
  })
  const week = [dayRanges[0][0], dayRanges[dayRanges.length - 1][1]]
  return (
    <>
      <div className='mw8 center tc'>
        <ChordContainer
          range={week.map(convertDateToEpoch)}
          date={date}
          title={dateRangeFormat(week)}
          height='calc(100vh - 100px)'
          legend
        />
      </div>
      <div className='mh2 mb4 flex flex-wrap'>
        {dayRanges.map(r => (
          <div key={r[0].getDay()} className='w-100 w-50-l w-33-xl'>
            <ChordContainer range={r.map(convertDateToEpoch)} date={r[0]} />
          </div>
        ))}
      </div>
    </>
  )
}

function ChordContainer ({
  range,
  date,
  title = dateFormat(date),
  height,
  legend
}) {
  const [dimensions, setDimensions] = useState({ width: -1, height: -1 })
  const [hoverText, setHoverText] = useState('')
  const queryVars = { fromHeight: range[0], toHeight: range[1] }
  const { loading, error, data } = useQuery(POPULATION_FLOW_MATRIX_QUERY, { variables: queryVars })
  if (error) {
    console.error(error)
    return null
  }
  const noData = !loading && !data?.populationFlowMatrix?.data.length
  const totalFilParts = formatNumber(data?.populationFlowMatrix?.totalFil || 0).split('.')
  return (
    <div className='bg-near-white mh1 mb2 br2 relative'>
      <hgroup className='pa4 cf absolute w-100 z-1'>
        <h2 className='dark-gray f6 fw6 ttu tracked lh-copy fl'>
          {title}<br />
          <span className='f7 gray'>{`${formatNumber(range[0])} → ${formatNumber(range[1])}`}</span>
        </h2>
        <h2 className='dark-gray f6 fw6 ttu tracked lh-copy fr' title={`${totalFilParts.join('.')} FIL`}>
          {totalFilParts[0]} FIL
        </h2>
      </hgroup>
      <Measure bounds onResize={contentRect => setDimensions(contentRect.bounds)}>
        {({ measureRef }) => (
          <div ref={measureRef} style={{ height }}>
            {dimensions.width > -1 && (
              noData
                ? <NoData height={dimensions.width} />
                : (
                  <Chord
                    loading={loading}
                    data={data?.populationFlowMatrix?.data || []}
                    names={data?.populationFlowMatrix?.names || []}
                    colors={(data?.populationFlowMatrix?.names || []).map(n => Colors[n] || stringToColour(n))}
                    totalFil={data?.populationFlowMatrix?.totalFil || 0}
                    width={dimensions.width}
                    height={height ? dimensions.height : dimensions.width}
                    onHover={setHoverText}
                  />
                  )
            )}
          </div>
        )}
      </Measure>
      {hoverText && (
        <div className='pa4 absolute bottom-0 z-1'>
          <HoverText text={hoverText} />
        </div>
      )}
      {legend && (
        <div className='pa4 absolute bottom-0 right-0 z-1'>
          <Legend />
        </div>
      )}
    </div>
  )
}

function Legend () {
  return (
    <div className='dark-gray f7 fw6 lh-copy pa3 br2 tl'>
      {Object.entries(Colors).map(([name]) => (
        <div key={name} className='mb1'>
          <LegendItem name={name} />
        </div>
      ))}
    </div>
  )
}

function Swatch ({ name, color }) {
  color = color || Colors[name]
  return <span className='dib w1 h1 mr1 v-mid' style={{ backgroundColor: color }} />
}

function LegendItem ({ name }) {
  return (
    <>
      <Swatch name={name} />
      <span className='dark-gray f7 fw6 lh-copy v-mid'>
        {name === 'Unknown Addresses' ? 'Unknown' : name}
      </span>
    </>
  )
}

function HoverText ({ text }) {
  return (
    <div className='dt w100 dark-gray f7 fw6 lh-copy bg-white pa3 pb2 br2 shadow-4'>
      {text.split('\n').map(t => (
        <HoverTextRow key={t} text={t} />
      ))}
    </div>
  )
}

function HoverTextRow ({ text }) {
  let [value, names] = text.split('FIL')
  value = value.split('.')[0]
  const [a, b] = names.split('→').map(x => x.trim())
  return (
    <div className='dt-row w100 tr'>
      <span className='dtc tl pb2'>
        <span className='bb black' style={{ borderColor: Colors[a] }}>{a}</span>
        <span className='silver'> to </span>
        <span className='bb black' style={{ borderColor: Colors[b] }}>{b}</span>
      </span>
      <span className='dtc tr'>
        <span className='black ml2'>{value}</span>
        <span className='silver mr2'> FIL</span>
      </span>
    </div>
  )
}

const Colors = {
  Miners: '#1AAAFF',
  Exchanges: '#83199A',
  'Burnt Funds': '#E02020',
  'Initial Pledge': '#55F281',
  'Unknown Addresses': '#CCCCCC'
}
