import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Loader } from 'rsuite'
import Navbar from './Navbar'
import Sankey from './Sankey'
import AddrLink from './AddrLink'
import WeekPicker from './WeekPicker'
import { MINER_PAGE_QUERY, MINER_FLOW_QUERY, GROUP_FLOW_QUERY } from './queries'
import { shortName, convertDateToEpoch, startOfWeek, endOfWeek } from './utils'

export default function MinerPage ({ fromDate, setFromDate }) {
  const date = fromDate || startOfWeek(new Date())
  const { group, address } = useParams()
  const { loading, error, data } = useQuery(MINER_PAGE_QUERY, { variables: { name: group } })
  if (error) console.error(error)
  console.log({ group, address, date, loading, error, data })
  return (
    <div className='avenir bg-white'>
      <Navbar>
        <WeekPicker date={date} onChange={setFromDate} />
      </Navbar>
      {loading ? <Loader center backdrop /> : null}
      {error ? <div className='center mw8 mv4 red'>{error.message}</div> : null}
      {!loading && !error ? <Content date={date} groups={data.listGroups} group={group} address={address} /> : null}
    </div>
  )
}

function Content ({ date, group, address, groups = [] }) {
  const range = [convertDateToEpoch(date), convertDateToEpoch(endOfWeek(date))]
  return (
    <div className='center mw8'>
      <GroupInfo groups={groups} group={group} currentAddress={address} />
      {address
        ? <AddressChartContainer range={range} address={address} />
        : <GroupChartContainer range={range} group={group} />}
    </div>
  )
}

function GroupInfo ({ groups = [], group, currentAddress }) {
  const [open, setOpen] = useState(!!currentAddress)
  const addresses = groups[0]?.addresses || []
  return (
    <div className='mb4'>
      {addresses.length
        ? (
          <details open={open}>
            <summary onClick={(e) => { e.preventDefault(); setOpen(!open) }} title='Show all addresses'><span className='fw7 mr2'>{shortName(group)} ({addresses.length})</span></summary>
            <AddrLink group={group} active={!currentAddress} to={`/miner/${group}`} text='All' />
            {addresses.map(addr => (
              <AddrLink key={addr} group={group} address={addr} active={addr === currentAddress} />
            ))}
          </details>
          )
        : <span className='fw7 mr2'>{shortName(group)}</span>}
    </div>
  )
}

function GroupChartContainer ({ range, group }) {
  // console.log('ChartContainer', address)
  const queryVars = { group, fromHeight: range[0], toHeight: range[1] }
  const { loading, error, data } = useQuery(GROUP_FLOW_QUERY, { variables: queryVars })
  if (error) {
    console.error(error)
    return null
  }
  let nodes = []
  let links = []
  if (!loading) {
    nodes = data.groupFlow.nodes
    links = data.groupFlow.links
  }
  return <Sankey loading={loading} data={{ nodes, links }} height={Math.max(window.innerHeight - 375, 640)} />
}

function AddressChartContainer ({ range, group, address }) {
  // console.log('ChartContainer', address)
  const queryVars = { address, fromHeight: range[0], toHeight: range[1] }
  const { loading, error, data } = useQuery(MINER_FLOW_QUERY, { variables: queryVars })
  if (error) {
    console.error(error)
    return null
  }
  let nodes = []
  let links = []
  if (!loading) {
    nodes = data.minerFlow.nodes
    links = data.minerFlow.links
  }
  return <Sankey loading={loading} data={{ nodes, links }} height={Math.max(window.innerHeight - 375, 640)} />
}
