import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { AutoComplete, InputGroup, Icon } from 'rsuite'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { LIST_GROUPS_QUERY } from './queries'

export function AddressForm ({ className, currentAddress, groupNames }) {
  const [searchParams] = useSearchParams()
  const [address, setAddress] = useState(currentAddress)
  const navigate = useNavigate()
  const view = (groupOrId) => {
    if (groupOrId === currentAddress) return
    const url = `/miner/${encodeURIComponent(groupOrId)}`
    const search = searchParams.toString()
    const to = search ? `${url}?${search}` : url
    navigate(to)
  }
  const onSubmit = e => {
    e.preventDefault()
    view(address)
  }
  return (
    <form onSubmit={onSubmit} className={className}>
      <InputGroup style={{ width: 300 }}>
        <AutoComplete
          data={groupNames}
          defaultValue={currentAddress}
          onSelect={({ value }) => view(value)}
          onChange={setAddress}
        />
        <InputGroup.Button onClick={onSubmit}>
          <Icon icon='search' />
        </InputGroup.Button>
      </InputGroup>
    </form>
  )
}

export default function AddressFormContainer (props) {
  const { data } = useQuery(LIST_GROUPS_QUERY)
  const groupNames = data?.listGroups.map(d => d.name)
  return <AddressForm groupNames={groupNames} {...props} />
}
