import { gql } from '@apollo/client'

export const CHAIN_HEAD_QUERY = gql`{
  chainHead {
    height
  }
}`

export const LIST_GROUPS_QUERY = gql`{
  listGroups {
    name
    addresses
  }
}`

export const MINER_PAGE_QUERY = gql`
  query MinerPage($name: String) {
    listGroups(name: $name) {
      name
      addresses
    }
  }
`

export const TOP_VOLUME_MATRIX_QUERY = gql`
  query GetTopVolumeMarix($fromHeight: Int!, $toHeight: Int!) {
    topVolumeMatrix(fromHeight: $fromHeight, toHeight: $toHeight) {
      data
      names
      totalFil
    }
  }
`

export const POPULATION_FLOW_MATRIX_QUERY = gql`
  query GetPopulationFlowMarix($fromHeight: Int!, $toHeight: Int!) {
    populationFlowMatrix(fromHeight: $fromHeight, toHeight: $toHeight) {
      data
      names
      totalFil
    }
  }
`

export const MINER_FLOW_QUERY = gql`
  query GetMinerFlow($address: String!, $fromHeight: Int!, $toHeight: Int!) {
    minerFlow (address: $address, fromHeight: $fromHeight, toHeight: $toHeight) {
      nodes {
        name
      }
      links {
        source
        target
        value
      }
    }
  }
`

export const GROUP_FLOW_QUERY = gql`
  query GetGroupFlow($group: String!, $fromHeight: Int!, $toHeight: Int!) {
    groupFlow (group: $group, fromHeight: $fromHeight, toHeight: $toHeight) {
      nodes {
        name
      }
      links {
        source
        target
        value
      }
    }
  }
`
