import React from 'react'
import * as d3 from 'd3'
import { DatePicker, RangeSlider } from 'rsuite'
import { genesis, convertEpochToDate, convertDateToEpoch, startOfDay, startOfTomorrow } from './utils'

const EPOCH_STEP = 120
const formatEpoch = d3.format(',')
const formatUTCTime = d => [d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds()].map(n => n.toString().padStart(2, '0')).join(':')

export function initialProps (d) {
  const date = startOfDay(d || new Date())
  const range = [
    convertDateToEpoch(date),
    convertDateToEpoch(startOfTomorrow(date))
  ]
  return { date, range }
}

export default function EpochSlider ({ date, maxDate = new Date(), range, onDateChange, onRangeChange }) {
  const minEpoch = convertDateToEpoch(date)
  const maxEpoch = convertDateToEpoch(startOfTomorrow(date))
  let i = 0
  const renderMark = mark => {
    i++
    if (i % 3 === 0) {
      const style = { position: 'absolute', marginLeft: '2px', transform: 'translate(-50%, 0)' }
      const title = `${formatUTCTime(convertEpochToDate(mark))} UTC`
      return <span className='f7 dib' style={style} title={title}>{formatEpoch(mark)}</span>
    }
  }
  return (
    <div className='flex-ns items-end-ns'>
      <div className='tl tl-ns pb3 flex-none-ns pr2-ns pl2 pl0-ns'>
        <DatePicker
          style={{ width: 140 }}
          cleanable={false}
          disabledDate={d => d.getTime() < genesis.getTime() || d.getTime() > maxDate.getTime()}
          onChange={d => {
            d = startOfDay(d)
            onDateChange(d)
            onRangeChange([convertDateToEpoch(d), convertDateToEpoch(startOfTomorrow(d))])
          }}
          value={date}
          oneTap
        />
      </div>
      <div className='ph3 ph1-ns flex-auto-ns'>
        <RangeSlider value={range} min={minEpoch} max={maxEpoch} onChange={onRangeChange} step={EPOCH_STEP} graduated renderMark={renderMark} />
      </div>
    </div>
  )
}
