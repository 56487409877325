import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'

/* Link preserves the current SearchParams by default.
 * Use <Link removeSearch to={...} /> to clear the query
 */
export default function LinkWithSearch ({ removeSearch = false, to, ...props }) {
  const [searchParams] = useSearchParams()
  if (!removeSearch) {
    const qs = searchParams.toString()
    to += qs ? `?${qs}` : ''
  }
  return <Link to={to} {...props} />
}
