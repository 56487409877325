export const genesis = new Date(Date.UTC(2020, 7, 24, 22))

const epochInterval = 1000 * 30

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function convertEpochToDate (height) {
  return new Date(genesis.getTime() + (epochInterval * height))
}

export function convertDateToEpoch (date) {
  return Math.max(0, Math.floor((date.getTime() - genesis.getTime()) / epochInterval))
}

export function startOfDay (date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function startOfDayUTC (date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

export function endOfDay (date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
}

export function endOfDayUTC (date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999))
}

export function addDays (date, num) {
  date = new Date(date)
  date.setDate(date.getDate() + num)
  return date
}

export function startOfWeek (date) {
  return addDays(startOfDay(date), -date.getDay())
}

export function endOfWeek (date) {
  return endOfDay(addDays(date, 6 - date.getDay()))
}

export function startOfTomorrow (date) {
  return startOfDay(addDays(date, 1))
}

export function dayFormat (date) {
  return DAYS[date.getDay()]
}

export function dateFormatShort (date) {
  return `${date.getDate()} ${MONTHS[date.getMonth()]}`
}

export function dateFormat (date) {
  return `${dayFormat(date)} ${dateFormatShort(date)}`
}

export function dateRangeFormat (dates) {
  return dates.map(dateFormatShort).join(' - ')
}

export function dateFormatWithDayName (date) {
  return `${DAYS[date.getDay()]} ${dateFormat(date)}`
}

const addrRegex = /^f[0-9][a-z0-9]{16,}$/

export function shortName (name) {
  return addrRegex.test(name) ? `${name.slice(0, 6)}...${name.slice(-6)}` : name
}

export function isAddr (str) {
  return addrRegex.test(str)
}

// https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
export function stringToColour (str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}
