import React, { Component } from 'react'
import * as d3 from 'd3'
import { Loader } from 'rsuite'
import { shortName, stringToColour } from './utils'

export default class Chord extends Component {
  componentDidMount () {
    this._chart = createChord(this._rootNode, this.props)
    if (this.props.data.length) {
      this._chart.update(this.props)
    }
  }

  componentDidUpdate () {
    if (this.props.data.length) {
      this._chart.update(this.props)
    }
  }

  render () {
    const { loading } = this.props
    return (
      <div className='relative'>
        {loading ? <Loader center backdrop content='Loading...' /> : null}
        <div ref={r => { this._rootNode = r }} />
      </div>
    )
  }
}

const formatValue = d3.format('.1~%')
const formatFil = d3.format(',')

// see: https://observablehq.com/@d3/chord-diagram
// see: https://github.com/d3/d3-chord#_chord
function createChord (rootNode, { data, names, width = 500, height = width }) {
  const svg = d3.create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`)

  const outerRadius = Math.min(width, height) * 0.5 - 100
  const innerRadius = outerRadius - 10
  const chord = d3.chord()
    .padAngle(10 / innerRadius)
    .sortSubgroups(d3.descending)
    .sortChords(d3.descending)
  const arc = d3.arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
  const ribbon = d3.ribbon()
    .radius(innerRadius - 1)
    .padAngle(1 / innerRadius)

  let ribbons = svg.append('g')
    .selectAll('path')

  let arcs = svg.append('g')
    .selectAll('path')

  let arcTicks = svg.append('g')
    .attr('font-size', 10)
    .attr('font-family', 'sans-serif')
    .selectAll('g')

  rootNode.appendChild(svg.node())

  return Object.assign(svg.node(), {
    update ({ data, names, colors = [], totalFil, onHover, onLabelClick = () => {} }) {
      const tickStep = d3.tickStep(0, 2, 100)
      const ticks = ({ startAngle, endAngle, value }) => {
        const k = (endAngle - startAngle) / value
        return d3.range(0, value, tickStep).map(value => ({ value, angle: value * k + startAngle }))
      }
      const chords = chord(data)
      const t = svg.transition().duration(750)
      const ribbonTitle = d => {
        const { index: srcIdx, value: srcVal } = d.source
        const { index: tgtIdx, value: tgtVal } = d.target
        return `${formatFil(srcVal * totalFil)} FIL  ${names[tgtIdx]} → ${names[srcIdx]}${srcIdx === tgtIdx || tgtVal === 0 ? '' : `\n${formatFil(tgtVal * totalFil)} FIL  ${names[srcIdx]} → ${names[tgtIdx]}`}`
      }

      ribbons = ribbons
        .data(chords, d => `${names[d.source.index]}->${names[d.target.index]}`)
        .join(
          enter => {
            const p = enter.append('path')
              .style('mix-blend-mode', 'multiply')
              .attr('fill', d => colors[d.source.index] || stringToColour(names[d.source.index]))
              .attr('fill-opacity', 0.8)
              .attr('d', d => {
                const startAngle = d.source.startAngle + ((d.source.endAngle - d.source.startAngle) / 2)
                const source = { startAngle, endAngle: startAngle + 0.01 }
                const target = d.source.index === d.target.index ? source : d.target
                return ribbon({ source, target })
              })
              .on('mouseover', (e, d) => {
                if (onHover) onHover(ribbonTitle(d))
                d3.select(e.target).attr('fill-opacity', 1)
              })
              .on('mouseout', e => {
                if (onHover) onHover('')
                d3.select(e.target).attr('fill-opacity', 0.8)
              })
            p.append('title').text(ribbonTitle)
            return p
          },
          update => update.call(path => {
            path
              .on('mouseover', (e, d) => {
                if (onHover) onHover(ribbonTitle(d))
                d3.select(e.target).attr('fill-opacity', 1)
              })
              .on('mouseout', e => {
                if (onHover) onHover('')
                d3.select(e.target).attr('fill-opacity', 0.8)
              })
              .transition(t)
              .attr('fill', d => colors[d.source.index] || stringToColour(names[d.source.index]))
              .attrTween('d', function (d) {
                const interpolate = d3.interpolate(this._current, d)
                this._current = interpolate(0)
                return t => ribbon(interpolate(t))
              })
            path.select('title').text(ribbonTitle)
          }),
          exit => exit.call(path => path.remove())
        )
        .call(path => path.transition(t).attr('d', ribbon))

      arcs = arcs
        .data(chords.groups, d => names[d.index])
        .join('path')
        .call(path => path
          .transition(t)
          .attr('fill', d => colors[d.index] || stringToColour(names[d.index]))
          .attrTween('d', function (d) {
            const interpolate = d3.interpolate(this._current, d)
            this._current = interpolate(0)
            return t => arc(interpolate(t))
          }))

      // TODO: animate
      arcTicks.select('g').remove()
      arcTicks = arcTicks
        .data(chords.groups, d => names[d.index])
        .join('g')

      const groupTicks = arcTicks
        .append('g')
        .selectAll('g')
        .data(ticks)
        .join('g')
        .attr('transform', d => `rotate(${d.angle * 180 / Math.PI - 90}) translate(${outerRadius},0)`)

      groupTicks.append('line')
        .attr('stroke', 'currentColor')
        .attr('x2', 6)

      groupTicks.append('text')
        .attr('x', 8)
        .attr('dy', '0.35em')
        .attr('transform', d => d.angle > Math.PI ? 'rotate(180) translate(-16)' : null)
        .attr('text-anchor', d => d.angle > Math.PI ? 'end' : null)
        .text(d => formatValue(d.value))

      arcTicks.select('text')
        .attr('font-weight', 'bold')
        .attr('cursor', 'pointer')
        .on('click', function (evt, d) { onLabelClick(names[d.index]) })
        .text(function (d) {
          return this.getAttribute('text-anchor') === 'end'
            ? `↑ ${shortName(names[d.index])}`
            : `${shortName(names[d.index])} ↓`
        })
    }
  })
}
