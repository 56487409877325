import { shortName } from './utils'
import Link from './Link'

export default function AddrLink ({
  group,
  address,
  active,
  text = shortName(address),
  to = active ? `/miner/${encodeURIComponent(group)}` : `/miner/${encodeURIComponent(group)}/${encodeURIComponent(address)}`
}) {
  const cls = `link ph2 pv1 br1 mr1 mt1 dib fw5 f7 ${active ? 'bg-light-blue' : 'bg-light-gray'}`
  return <Link className={cls} to={to} title={address}>{text}</Link>
}
