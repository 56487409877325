/* eslint-env browser */
import React, { Component, useState } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { Loader, Button } from 'rsuite'
import logo from './fil-flow-logo.svg'
import { CHAIN_HEAD_QUERY } from './queries'

const CREDS_STORAGE_KEY = 'credentials'

export default class Auth extends Component {
  constructor () {
    super()
    this.state = { loggingIn: false, loggedIn: false, errorMessage: '' }
  }

  async componentDidMount () {
    const creds = localStorage.getItem(CREDS_STORAGE_KEY)
    if (!creds) return
    this.setState({ loggingIn: true })
    try {
      const client = await this.tryLogin(creds)
      this._client = client
    } catch (err) {
      this.setState({ loggingIn: false })
      return console.warn('failed to login with existing credentials', err)
    }
    this.setState({ loggingIn: false, loggedIn: true })
  }

  async tryLogin (creds) {
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      uri: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:7103/graphql',
      headers: { Authorization: `Basic ${creds}` }
    })
    await client.query({ query: CHAIN_HEAD_QUERY })
    return client
  }

  async handleLogin ({ user, pass }) {
    this.setState({ loggingIn: true, errorMessage: '' })
    const creds = `${btoa(user)}:${btoa(pass)}`
    try {
      const client = await this.tryLogin(creds)
      this._client = client
    } catch (err) {
      this.setState({ loggingIn: false, errorMessage: 'Login failed' })
      return console.error('failed to login', err)
    }
    localStorage.setItem(CREDS_STORAGE_KEY, creds)
    this.setState({ loggingIn: false, loggedIn: true })
  }

  render () {
    const { loggingIn, loggedIn, errorMessage } = this.state
    if (loggedIn) {
      return (
        <ApolloProvider client={this._client}>
          {this.props.children}
        </ApolloProvider>
      )
    }
    return <LoginForm loggingIn={loggingIn} errorMessage={errorMessage} onSubmit={u => this.handleLogin(u)} />
  }
}

function LoginForm ({ loggingIn, errorMessage, onSubmit }) {
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  return (
    <form onSubmit={e => { e.preventDefault(); onSubmit({ user, pass }) }} className='pa4 br2 mv5 mh2 center bg-white' style={{ maxWidth: '376px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 30px 0px' }}>
      <div className='tc mb4'><img src={logo} alt='logo' /></div>
      <h1 className='f5 fw6 near-black tc lh-solid'>Login</h1>
      <div className='mv3'>
        <input className='input-reset ba br2 db w-100 pa2 b--black-20' value={user} onChange={e => setUser(e.target.value)} disabled={loggingIn} placeholder='Username' autoComplete='username' />
      </div>
      <div className='mv3'>
        <input type='password' className='input-reset ba br2 db w-100 pa2 b--black-20' value={pass} onChange={e => setPass(e.target.value)} disabled={loggingIn} placeholder='Password' autoComplete='current-password' />
        {errorMessage ? <div className='red mv2'>{errorMessage}</div> : null}
      </div>
      <div className='mv3'>
        {loggingIn
          ? <div className='tc'><Loader content='Logging in...' /></div>
          : <Button type='submit' appearance='primary' block disabled={loggingIn}>Login</Button>}
      </div>
    </form>
  )
}

export function logout () {
  localStorage.removeItem(CREDS_STORAGE_KEY)
  window.location.reload()
}
