import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import * as d3Sankey from 'd3-sankey'
import { Loader } from 'rsuite'
import { shortName, stringToColour } from './utils'
import NoData from './NoData'

const LINK_COLOR = '#dddddd'

export default function Sankey ({ loading, height, data }) {
  const noData = !loading && data.links.length === 0
  const containerEl = useRef(null)
  useEffect(() => {
    const width = containerEl.current.clientWidth
    if (data.links.length !== 0) {
      createChart({ width, height, data })
    }
  })
  return (
    <div className='relative' ref={containerEl}>
      {loading ? <Loader center backdrop content='Loading...' /> : null}
      {noData ? <NoData height={height} /> : null}
      <svg id='sankey' style={{ width: '100%', height, display: noData ? 'none' : 'block' }} />
    </div>
  )
}

function createChart ({ data, width, height }) {
  const sankey = d3Sankey.sankey()
    .nodeId(d => d.name)
    .nodeAlign(d3Sankey.sankeyJustify)
    .nodeSort(null)
    .nodeWidth(15)
    .nodePadding(10)
    .extent([[0, 5], [width, height - 5]])

  const svg = d3.select('#sankey')

  const { nodes, links } = sankey({
    nodes: data.nodes.map(d => Object.assign({}, d)),
    links: data.links.map(d => Object.assign({}, d))
  })

  selectAppendById(svg, 'g', 'bar')
    .selectAll('rect')
    .data(nodes, function (d) { return d.name })
    .join('rect')
    .attr('x', d => d.x0 + 1)
    .attr('y', d => d.y0)
    .attr('height', d => d.y1 - d.y0)
    .attr('width', d => d.x1 - d.x0 - 2)
    .attr('fill', d => {
      return toSankeyColor(d.name.replace('→ ', ''))
    })
    .append('title')
    .text(d => `${d.name.replace('→ ', '')}\n${d.value.toLocaleString()} FIL`)

  const link = selectAppendById(svg, 'g', 'link')
    .attr('fill', 'none')
    .selectAll('g')
    .data(links, function (d) { return d.source + d.target })
    .join('g')
    // maybe to much rainbow if we colour flows by recipient
    // .attr('stroke', d => stringToColour(d.target.name))
    .attr('stroke', d => d3.color(d.color) || LINK_COLOR)
    .style('mix-blend-mode', 'multiply')

  selectAppendById(link, 'path', 'link-path')
    .attr('d', d3Sankey.sankeyLinkHorizontal())
    .attr('stroke-width', d => Math.max(1, d.width))

  selectAppendById(link, 'title', 'link-title')
    .text(d => `${d.source.name} → ${d.target.name.replace('→ ', '')}\n${d.value.toLocaleString()}`)

  selectAppendById(svg, 'g', 'text')
    .style('font-size', '12px')
    .style('font-family', '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"')
    .selectAll('text')
    .data(nodes, function (d) { return d.name })
    .join('text')
    .attr('x', d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
    .attr('y', d => (d.y1 + d.y0) / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', d => d.x0 < width / 2 ? 'start' : 'end')
    .text(d => shortName(d.name.replace('→ ', '')))
    .append('tspan')
    .attr('fill', '#0090ff')
    .attr('fill-opacity', 1)
    .text(d => ` ${d.value.toLocaleString()} FIL ${d.x0 < width / 2 ? '→' : ''}`)
}

function selectAppendById (parent, tag, id) {
  return parent.selectAll(`#${id}`)
    .data(function (d) { return [d] })
    .join(
      enter => enter.append(tag).attr('id', id)
    )
}

function toSankeyColor (str) {
  if (str.replace('→ ', '') === 'Unknown Addresses') {
    return d3.color(LINK_COLOR).darker(0.5)
  }
  return stringToColour(str)
}
